<template>
  <button
    :class="[
      type == 'secondary' ? 'secondary' : 'primary',
      size == 'small' ? 'small' : 'defaultSize',
      theme == 'dark' && 'hover:!bg-opacity-20',
      $props.class,
    ]"
  >
    <Label3
      v-if="size == 'small'"
      class="cursor-pointer"
      :class="[theme == 'dark' && 'text-white-500']"
    >
      {{ label ?? "Button" }}
    </Label3>
    <Label2 v-else class="cursor-pointer" :class="[theme == 'dark' && 'text-white-500']">
      {{ label ?? "Button" }}
    </Label2>
  </button>
</template>

<script lang="ts">
export default defineComponent({
  name: "SalusButton",
  props: {
    label: String,
    type: String,
    size: String,
    theme: String,
    class: String,
  },
});
</script>

<style scoped>
button {
  @apply select-none selection:select-none h-auto min-w-fit px-4 hover:scale-[101%] active:scale-100;
}

button.primary {
  @apply bg-primary-500 hover:bg-primary-600 text-white-500 shadow-sm shadow-neutral-600 active:shadow-none disabled:bg-neutral-200 disabled:shadow-none disabled:text-neutral-400;
}

button.secondary {
  @apply bg-transparent hover:bg-primary-200 active:bg-primary-300 text-primary-500 border border-primary-300 disabled:bg-transparent disabled:border-neutral-300 disabled:text-neutral-400;
}

button.small {
  @apply rounded-xl py-3;
}
button.defaultSize {
  @apply rounded-2xl py-4;
}
</style>
